<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-core">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item no-padding">
          <img
            class="logo"
            width="215"
            height="36"
            src="@/assets/images/logo.svg"
            alt="logo"
          />
        </router-link>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="menu"
          @click="toggleMenu"
          :class="{ 'is-active': isOpen }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="menu" class="navbar-menu" :class="{ 'is-active': isOpen }">
        <div class="navbar-start">
          <a class="navbar-item" @click="scrollTo('about')">O NAS</a>
          <a class="navbar-item" @click="scrollTo('assortment')">ASORTYMENT</a>
          <a class="navbar-item" @click="goGallery">GALERIA</a>
          <a class="navbar-item" @click="scrollToContact()">KONTAKT</a>
          <a
            href="https://www.facebook.com/TechnikerZT"
            rel="noreferrer"
            target="_blank"
            class="navbar-item is-facebook"
          >
            <span class="facebook-icon"></span>
          </a>
          <a href="tel:[618780824]" class="navbar-item is-highlighted">
            61 878 08 24
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'Navbar',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    async scrollTo(elementId) {
      smoothscroll.polyfill();
      this.isOpen = false;
      if (this.$router.currentRoute.name !== 'Home')
        await this.$router.push('/');
      const element = document.getElementById(elementId);
      element.scrollIntoView({ behavior: 'smooth' });
    },

    async scrollToContact() {
      smoothscroll.polyfill();
      this.isOpen = false;
      const element = document.getElementById('contact-form');
      element.scrollIntoView({ behavior: 'smooth' });
    },

    async goGallery() {
      this.isOpen = false;
      await this.$router.push('/galeria');
    },

    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  width: 100%;
  margin: 0 auto;
  background-color: $white-bis;
  color: $black;
  padding: 1rem;
  font-size: 0.85rem;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.04) 0 3px 5px;
}

.navbar-core {
  margin: 0 auto;
  display: flex;
}

.navbar-item {
  color: $grey;
  transition: 250ms cubic-bezier(0.65, 0, 0.35, 1);

  &:hover,
  &:active,
  &:focus {
    color: $grey;
    background-color: $primary;
  }

  img {
    max-height: 2.25rem;
  }
}

.navbar-start {
  margin: 0 0 0 auto;
}

.is-highlighted {
  background-color: $primary !important;
  text-align: center;
}

.is-facebook {
  background-color: #1778f2 !important;
  text-align: center;

  .facebook-icon {
    background: url('../assets/images/facebook-f-brands.svg') no-repeat top left;
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 12px;
    vertical-align: middle;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-core {
    width: 100%;
  }

  .navbar-menu {
    display: block;
    position: absolute;
    box-shadow: none;
    width: 100%;
    margin: 65px 0 0 -1rem;
    padding: 1.5rem;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50%);
    transition-duration: 350ms;
    transition-property: opacity, visibility, transform;
    transition-delay: 350ms;

    &.is-active {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }

    .navbar-item {
      &:not(:first-child),
      &:not(:last-child) {
        margin: 1rem auto;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .navbar-core {
    width: 80%;
  }

  .navbar-item {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>
